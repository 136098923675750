import React from "react";

export default function Referenser() {
  return (
    <div className="mx-4">
      <h3 className="text-3xl mb-8 font-bold border-b-4 pb-4 border-brand ">
        Referenser
      </h3>
      <p>
        Vårt kundregister är både stort och omväxlande. Med stora och små
        företag som alla kräver kvalificerad kunskap och kvalitet. Inget uppdrag
        är för stort eller litet. Därför är variationen på uppdragsgivare stor.
        Den gemensamma nämnaren är att alla ställer höga krav på fasta
        leveranstider och priser. Önskar du komma i kontakt med några av våra
        kunder, kontakta oss så får du namn och telefonnummer till aktuell
        person.{" "}
      </p>
    </div>
  );
}
