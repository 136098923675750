import React from "react";
import IntroBox from "./IntroBox";
import { useStaticQuery, graphql } from "gatsby";

export default function Intro() {
  const data = useStaticQuery(graphql`
    {
      allWpPage(filter: { wpParent: { node: { slug: { eq: "startsida" } } } }) {
        nodes {
          title
          id
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
  const posts = data.allWpPage.nodes;
  return (
    <section className="" id="om">
      <div className="grid md:grid-cols-3 gap-4">
        {posts.map((post) => {
          return <IntroBox key={post.id} post={post} />;
        })}
      </div>
    </section>
  );
}
