import React from "react";
import Counter from "../components/Counter";
import Intro from "../components/index/Introboxes";
import Referenser from "../components/index/Referenser";
import Systerbolag from "../components/index/Systerbolag";
import Logos from "../components/index/Logos";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout isHome>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Startsida"
      />
      <div className="">
        <section className="px-8 py-16">
          <Intro />
        </section>
      </div>
      <div className="bg-brand py-8">
        <section className="mx-auto my-8  max-w-2xl px-8">
          <div className=" bg-white shadow-lg rounded p-8">
            <Counter />
          </div>
        </section>
      </div>
      <section className="mx-auto max-w-4xl pt-16 ">
        <Referenser />
      </section>
      <section className="mx-auto max-w-4xl pt-16">
        <Logos />
      </section>
      <section className="mx-auto max-w-4xl pt-16 pb-32">
        <Systerbolag />
      </section>
    </Layout>
  );
}

export default IndexPage;
