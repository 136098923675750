import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Fade from "react-reveal/Fade";

export default function IntroBox({ post }) {
  const { title, featuredImage, content } = post;
  const image = getImage(featuredImage.node.localFile);

  return (
    <Fade>
      <div className="h-full flex flex-col">
        <div className="h-full flex-1 ">
          <GatsbyImage image={image} alt={title} className="" />
        </div>
        <div className="flex-grow">
          <div className="w-full h-full flex  flex-col space-y-4 pt-4">
            <h3 className="text-2xl font-bold">{title}</h3>
            <div>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
