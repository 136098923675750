import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import LogoItem from "./LogoItem";
export default function Logos() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "logos" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  `);
  const posts = data.allFile.nodes;

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 md:grid-cols-5 gap-4 md:gap-12 multiply">
        {posts.map((post, i) => {
          return <LogoItem key={i} post={post} />;
        })}
      </div>
    </div>
  );
}
