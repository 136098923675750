import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function Counter() {
  const [value, setValue] = useState(null);
  const [overlap, setOverlap] = useState(false);

  const handleClick = () => setOverlap(!overlap);
  let computedValue;
  if (overlap) {
    computedValue = Math.ceil(value / 7.7);
  } else {
    computedValue = Math.ceil(value / 8.4);
  }

  return (
    <div className="space-y-6">
      <h3 className="text-2xl font-medium">Hur mycket körplåt behöver jag?</h3>
      <p className="">Skriv in hur många &#13217; du önskar täcka</p>
      <div className="flex justify-center md:justify-start">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onClick={handleClick}
                checked={overlap}
              />
            }
            label="Överlappande (20cm)"
          />
        </FormGroup>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <TextField
          id="outlined-number"
          label="antal &#13217;"
          type="number"
          defaultValue="0"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setValue(e.target.value)}
        />

        <div className="space-x-4">
          <span className="text-4xl text-brand">
            {computedValue} {computedValue === 1 ? "körplåt" : "körplåtar"}
          </span>
        </div>
      </div>
    </div>
  );
}
