import React from "react";

export default function Systerbolag() {
  return (
    <div className="mx-4">
      <div className="">
        <h3 className="text-3xl mb-8 font-bold border-b-4 pb-4 border-brand ">
          Systerbolag
        </h3>
        <p>
          Tillsammans med våra systerbolag ingår vi i en stark företagsgrupp som
          idag är ledande inom sina respektive affärsområden. Gruppen består
          även av <a href="https://stabteknik.se">Stab Teknik AB</a>,{" "}
          <a href="http://krandepan.se">Krandepån Södra AB</a>
        </p>
      </div>
    </div>
  );
}
